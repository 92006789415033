<template>
  <div id="mian">
    <div class="header">
      <span @click="toUrl('ledger_detail_jbxx', { id: $route.query.id })">基本信息</span>
      <span @click="toUrl('ledger_detail_jsxx', { id: $route.query.id })" id="fzcolor">结算信息</span>
      <span @click="toUrl('ledger_detail_glqd', { id: $route.query.id })">关联渠道</span>
      <span @click="toUrl('ledger_detail_dzzb', { id: $route.query.id })">电子账簿</span>
      <span @click="toUrl('ledger_detail_cpxx', { id: $route.query.id })">产品信息</span>
      <span @click="toUrl('ledger_detail_bccl', { id: $route.query.id })">补充材料</span>
    </div>
    <div v-auth="'ELECTRLED:MCHCOM:MCHLIST:SETTLE'">
      <div class="desc">
        <h5 class="jsxx">结算信息</h5>
      </div>
      <div class="tab1">
          <table class="tab">
            <tr class="tab_title">
              <td style="width: 171px">
                <p>账户名</p>
              </td>
              <td style="width: 163px">
                <p>账号</p>
              </td>
              <td style="width: 95px">
                <p>联行号</p>
              </td>
              <td style="width: 269px">
                <p>账户类型</p>
              </td>
            </tr>
            <tr>
              <td style="width: 171px">
                <p>{{ settlementsData.accountName }}</p>
              </td>
              <td style="width: 163px">
                <p>{{ settlementsData.accountNo }}</p>
              </td>
              <td style="width: 95px">
                <p>{{ settlementsData.interBranchesNo }}</p>
              </td>
              <td style="width: 269px">
                <p>{{ accountType[settlementsData.accountType] }}</p>
              </td>
            </tr>
          </table>
      </div>
    </div>
  </div>
</template>
<script>
import {ledgerDetail} from "@/api/user/shopManager.js"; 
export default {
  data() {
    return {
      settlementsData: [],
      accountType: {
        COMPANY: "企业",
        PERSONAL: "个人",
      },
    };
  },
  computed: {

  },
  mounted() {

  },
  created() {
    if (this.$route.query.id) {
      this.getDetail();
    }
  },
  methods: {
    // 获取详情
    getDetail() {
      ledgerDetail(this.$route.query.id).then((res) => {
        if (res.resultStatus) {
          this.settlementsData = res.resultData.settlements;
          console.log(this.settlementsData);
        }
      });
    },   
    // 页面跳转
    toUrl(name,query) {
      this.$router.push({
        name: name,
        query: {
          ...query,
        },
      });
    },
  },
  beforeDestroy() {

  },
};
</script>
<style scoped>
@import "../../../assets/css/desc.css";
.tab1{
  min-height: auto !important;
}
.next {
  width: 120px;
  height: 32px;
  padding: 0;
  background: var(--themeColor);
  border-color: var(--themeColor);
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  border-color: #dce0e6;
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  color: var(--title);
  font-size: 12px;
}

.btn {
  width: 100%;
  margin-top: 50px;
  text-align: center;
  height: 33px;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: var(--themeColor);
  border-color: var(--themeColor);
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: var(--title);
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: var(--label);
}

.overAccount_ul_title i {
  color: var(--themeColor);
  padding-right: 3px;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}
</style>
